export class Page {
    per_page: number = 25;
    total: number = 1;
    from: number = 1;
    to: number = 1;
    currentPage : number = 1;
    first_page_url : string;
    last_page_url : string;
    next_page_url : string;
    prev_page_url : string; 
    last_page : number;
    pageNumber: number;

    constructor(input?){
        input = input || {};
        this.total = input.total || '';
        this.per_page = input.per_page || '';
        this.from = input.from || '';
        this.to = input.to || '';
        this.currentPage = input.currentPage || '';
        this.first_page_url = input.first_page_url || '';
        this.last_page_url = input.last_page_url || '';
        this.next_page_url = input.next_page_url || '';
        this.prev_page_url = input.prev_page_url || '';
        this.last_page = input.last_page || '';
    }

}